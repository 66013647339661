import * as Types from '../../../types/graphql/core-types';

import type {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
import {gql} from '@apollo/client';
export type SharedDataContextQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  enabledBetasNames:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
}>;

export type SharedDataContextQuery = {
  __typename: 'QueryRoot';
  shop: {
    __typename: 'Shop';
    id: string;
    name: string;
    contactEmail: string;
    currencyCode: Types.CurrencyCode;
    url: string;
    enabledFlags: Array<boolean>;
    myshopifyDomain: string;
    ianaTimezone: string;
  };
  staffMember: {
    __typename: 'StaffMember';
    id: string;
    pin: string | null;
    email: string;
    permissions: {
      __typename: 'StaffMemberPermissions';
      userPermissions: Array<Types.StaffMemberPermission>;
    };
  } | null;
  app: {
    __typename: 'App';
    id: string;
    appStoreAppUrl: string | null;
    handle: string | null;
  } | null;
};

export const SharedDataContextDocument = gql`
  query SharedDataContext($id: ID!, $enabledBetasNames: [String!]!) {
    shop {
      id
      name
      contactEmail
      currencyCode
      url
      enabledFlags(names: $enabledBetasNames)
      myshopifyDomain
      ianaTimezone
    }
    staffMember(id: $id) {
      id
      pin
      permissions {
        userPermissions
      }
      email
    }
    app {
      id
      appStoreAppUrl
      handle
    }
  }
` as unknown as DocumentNode<
  SharedDataContextQuery,
  SharedDataContextQueryVariables
>;
