import {useLocation} from '@remix-run/react';
import {useEffect} from 'react';

import {useTrackingProducer} from '~/hooks';

export function TrackPageView() {
  const {trackPageView} = useTrackingProducer();
  const {pathname} = useLocation();

  useEffect(() => {
    trackPageView();
  }, [trackPageView, pathname]);

  return null;
}
