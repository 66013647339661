import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import {InMemoryCache, defaultDataIdFromObject} from '@apollo/client/cache';
import {onError} from '@apollo/client/link/error';
import {
  Provider as AppBridgeProvider,
  Loading,
} from '@shopify/app-bridge-react';
import {AppProvider} from '@shopify/polaris-internal';
import {type CurrencyCode, I18nContext} from '@shopify/react-i18n';
import {ReportifyClient, ReportifyProvider} from '@shopify/reportify-react';
import React, {useMemo} from 'react';

import {
  useAppBridgeStateStaffMember,
  useAuthenticatedFetch,
  useSessionStorage,
} from '~/hooks';
import {type AppData} from '~/types/shared';
import {isErrorExpected} from '~/utils/expectedErrors';

import {ConfirmLeaveModalContextProvider} from '../ConfirmLeaveModalContext';
import {SessionIdContextProvider} from '../SessionIdContext';

import {RenderChildrenOrThrow, SmartLink} from './components';
import {SharedDataContext, notify} from './context';
import {AnalyticsTokenDocument} from './graphql/AnalyticsTokenQuery.core.graphql.generated';
import {
  useBugsnag,
  useEnableMetaDefinitions,
  useI18nManager,
  useLoadShopData,
} from './hooks';

interface AppSetupContextProps {
  data: AppData;
  children: React.ReactNode;
  /** The React node to show while loading the initialization network request */
  loadingFallback?: React.ReactNode;
  skipMetaEnablement?: boolean;
}

/**
 * The shared context between our ModalApp and App. Contains all of the context that should be in both.
 * We need to wrap the Inner component with this one so that it has access to useAppBridge (via useAppBridgeStateStaffMember)
 */
export function AppSetupContext({
  data,
  children,
  loadingFallback,
  skipMetaEnablement,
}: AppSetupContextProps) {
  return (
    <AppBridgeProvider
      config={{
        apiKey: data.apiKey,
        forceRedirect: true,
        host: data.host,
      }}
    >
      <Inner
        data={data}
        loadingFallback={loadingFallback}
        skipMetaEnablement={skipMetaEnablement}
      >
        {children}
      </Inner>
    </AppBridgeProvider>
  );
}

interface InnerProps {
  data: AppData;
  children: React.ReactNode;
  /** The React node to show while loading the network request */
  loadingFallback?: React.ReactNode;
  skipMetaEnablement?: boolean;
}
function Inner({
  children,
  data,
  loadingFallback,
  skipMetaEnablement = false,
}: InnerProps) {
  const authenticatedFetch = useAuthenticatedFetch();
  const staffMember = useAppBridgeStateStaffMember();
  const staffMemberRef = React.useRef(staffMember);
  staffMemberRef.current = staffMember;

  const [preferredLocale] = useSessionStorage('preferredLocale', '');
  const [preferredCurrencyCode] = useSessionStorage(
    'preferredCurrencyCode',
    '' as CurrencyCode,
  );

  const apolloClient = useMemo(() => {
    // The query params are added for logging purposes, they have no effect in the BE
    const enrichUrlLink = new ApolloLink((operation, forward) => {
      const operationType = (() => {
        const source = operation.query.loc?.source?.body;
        if (source) {
          const typeRegex = /(query|mutation|subscription)\s/;
          const matches = typeRegex.exec(source);

          return matches?.[1];
        }
      })();
      let uri = `/graphql/proxy?type=${operationType}&operation=${operation.operationName}`;

      if (!operation.getContext().skipLocale) {
        uri += `&locale=${staffMemberRef.current?.locale ?? 'en'}`;
      }

      operation.setContext({
        uri,
      });
      return forward(operation);
    });

    const errorLink = onError(
      ({graphQLErrors, operation, forward, networkError}) => {
        const isExpected = isErrorExpected(
          new ApolloError({graphQLErrors, networkError}),
        );
        if (graphQLErrors && !isExpected) {
          graphQLErrors.forEach(({message}) => {
            const error = new Error(message);
            notify({
              errorClass: 'GraphQLError',
              stack: error.stack,
              errorMessage: message,
            });
          });
        }

        return forward(operation);
      },
    );

    const link = from([
      enrichUrlLink,
      errorLink,
      createHttpLink({
        credentials: 'include',
        fetch: authenticatedFetch,
      }),
    ]);

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        dataIdFromObject: (object) => {
          // We need to use the cache key for the OnlineStore object, otherwise creating new filters (maybe specifically metafield filters) can cause infinite revalidation loops in StrictMode with notifyOnNetworkStatusChange
          if (object.__typename === 'OnlineStore') {
            return `${object.__typename}:cache`;
          }
          return defaultDataIdFromObject(object);
        },
      }),
    });

    return client;
  }, [authenticatedFetch]);

  const reportifyClient = useMemo(
    () =>
      new ReportifyClient({
        source: 'shopify-seach-and-discovery',
        refetchToken: async () => {
          const {data} = await apolloClient.query({
            query: AnalyticsTokenDocument,
            fetchPolicy: 'network-only',
          });

          return data?.shop?.authorizedAnalyticsToken || '';
        },
      }),
    [apolloClient],
  );

  const {
    sharedDataLoading,
    sharedDataValue,
    sharedDataError,
    dismissedCards,
    dismissibleCardsLoading,
    refetchDismissibleCards,
  } = useLoadShopData({
    userId: staffMember?.id,
    client: apolloClient,
  });

  const {enablingDefinitions, error: enablingDefinitionsError} =
    useEnableMetaDefinitions({
      skip: skipMetaEnablement,
      client: apolloClient,
    });

  useBugsnag({
    nodeEnv: data.nodeEnv,
    appEnv: data.appEnv,
    shop: sharedDataValue?.shop,
    userId: staffMember?.id,
    revision: data.revision,
  });

  const {i18nManager, i18n} = useI18nManager({
    locale: preferredLocale || staffMember?.locale,
    currencyCode: preferredCurrencyCode || sharedDataValue?.shop.currencyCode,
  });

  const contextValue = useMemo(() => {
    if (!sharedDataValue || !dismissedCards) {
      return undefined;
    }

    /*
     * non-unified-admin example:
     * domain: "shop1.shopify.unified-admin.jason-addleman.us.spin.dev"
     * host:   "shop1.shopify.unified-admin.jason-addleman.us.spin.dev/admin"
     *
     * unified-admin example:
     * domain: "shop1.shopify.unified-admin.jason-addleman.us.spin.dev"
     * host:   "admin.web.unified-admin.jason-addleman.us.spin.dev/store/shop1"
     */
    const host = atob(data.host);
    const unifiedAdminEnabled = !host.startsWith(
      sharedDataValue.shop.myshopifyDomain,
    );

    return {
      ...sharedDataValue,
      unifiedAdminEnabled,
      dismissedCards,
      dismissibleCardsLoading,
      refetchDismissibleCards,
    };
  }, [
    sharedDataValue,
    dismissedCards,
    data.host,
    dismissibleCardsLoading,
    refetchDismissibleCards,
  ]);

  const error = sharedDataError || enablingDefinitionsError;
  const anyLoading =
    enablingDefinitions ||
    sharedDataLoading ||
    dismissibleCardsLoading ||
    !i18n;

  if (anyLoading || (!contextValue && !error)) {
    return loadingFallback ? <>{loadingFallback}</> : <Loading />;
  }

  return (
    <SharedDataContext.Provider value={contextValue}>
      <I18nContext.Provider value={i18nManager}>
        <ApolloProvider client={apolloClient}>
          <AppProvider i18n={i18n.translations} linkComponent={SmartLink}>
            <RenderChildrenOrThrow error={error}>
              <ReportifyProvider client={reportifyClient}>
                <ConfirmLeaveModalContextProvider>
                  <SessionIdContextProvider>
                    {children}
                  </SessionIdContextProvider>
                </ConfirmLeaveModalContextProvider>
              </ReportifyProvider>
            </RenderChildrenOrThrow>
          </AppProvider>
        </ApolloProvider>
      </I18nContext.Provider>
    </SharedDataContext.Provider>
  );
}
