import {Link} from '@remix-run/react';
import {type UnstyledLinkProps} from '@shopify/polaris-internal';
import React from 'react';

export const SmartLink = React.forwardRef<HTMLAnchorElement, UnstyledLinkProps>(
  ({url, children, ...rest}, ref) => {
    // if the url starts with http:// or https://, handle it as an anchor
    if (/^https?:\/\//.test(url)) {
      return (
        <a
          href={url}
          rel={rest.target === '_blank' ? 'noopener noreferrer' : undefined}
          {...rest}
        >
          {children}
        </a>
      );
    }

    return (
      <Link to={url} {...rest} ref={ref}>
        {children}
      </Link>
    );
  },
);
SmartLink.displayName = 'SmartLink';
