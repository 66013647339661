import * as Types from '../../../types/graphql/core-types';

import type {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
import {gql} from '@apollo/client';
export type AnalyticsTokenQueryVariables = Types.Exact<{[key: string]: never}>;

export type AnalyticsTokenQuery = {
  __typename: 'QueryRoot';
  shop: {
    __typename: 'Shop';
    id: string;
    authorizedAnalyticsToken: string | null;
  };
};

export const AnalyticsTokenDocument = gql`
  query AnalyticsToken {
    shop {
      id
      authorizedAnalyticsToken
    }
  }
` as unknown as DocumentNode<AnalyticsTokenQuery, AnalyticsTokenQueryVariables>;
