import {Redirect} from '@shopify/app-bridge/actions';
import {authenticatedFetch} from '@shopify/app-bridge/utilities';
import {useAppBridge} from '@shopify/app-bridge-react';
import fetch from 'cross-fetch';
import {useCallback} from 'react';

export function useAuthenticatedFetch() {
  const app = useAppBridge();

  const customFetchFunction = useCallback(
    async (uri: RequestInfo | URL, options?: RequestInit) => {
      const authenticatedFetchFunction = authenticatedFetch(app, fetch);

      const response = await authenticatedFetchFunction(uri, options);
      if (
        response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') ===
        '1'
      ) {
        const authUrlHeader =
          response.headers.get(
            'X-Shopify-API-Request-Failure-Reauthorize-Url',
          ) || '/login';

        const url = new URL(authUrlHeader, window.location.href);
        url.searchParams.set('return_to', window.location.pathname);

        const redirect = Redirect.create(app);
        redirect.dispatch(Redirect.Action.REMOTE, url.toString());

        // the redirect above isn't immediate. let's just wait some arbitary time so we don't show an error to the user (thus reporting to Bugsnag)
        // if the redirect somehow takes more than 10 seconds (it shouldn't and won't), then we can show an error to the user.
        await new Promise((resolve) => setTimeout(resolve, 10_000));
      }

      return response;
    },
    [app],
  );

  return customFetchFunction;
}
