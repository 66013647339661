import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {useEffect} from 'react';

import {BUGSNAG_API_KEY} from '~/utils/constants';

interface startBugsnagProps {
  nodeEnv: string;
  appEnv: string;
  revision: string;
}

interface useBugsnagProps {
  nodeEnv: string;
  appEnv: string;
  userId: number | undefined;
  revision: string;
  shop:
    | {
        id: string;
        name: string;
        url: string;
      }
    | undefined;
}

export function startBugsnag({nodeEnv, revision, appEnv}: startBugsnagProps) {
  if (!Bugsnag.isStarted()) {
    Bugsnag.start({
      // If changed, update also in upload-sourcemaps.ts
      apiKey: BUGSNAG_API_KEY,
      appVersion: revision,
      plugins: [new BugsnagPluginReact()],
      releaseStage: appEnv,
      collectUserIp: false,
      onError: (event) => {
        // eslint-disable-next-line no-console
        console.error(event);

        // this logic was suggested by Cal Irvine and blessed by a Remix dev (Matt Brophy): https://github.com/Shopify/shop-importer-app/blob/3a398c636fbcb90474ec63b4b6d55bcc3cdeba53/app/utilities/bugsnag/common.ts#L33-L36
        // Note: this doesn't seem to work yet, but I suspect it's related to https://github.com/remix-run/remix/issues/10005
        if (
          event.originalError.message === 'Unexpected Server Error' ||
          event.errors.some((error) => !error.stacktrace.length)
        ) {
          return false;
        }

        // Disable bugsnag for development
        return nodeEnv === 'production';
      },

      featureFlags: [{name: 'Remix', variant: 'true'}],
    });
  }
}

export function useBugsnag({
  nodeEnv,
  userId,
  shop,
  revision,
  appEnv,
}: useBugsnagProps) {
  startBugsnag({
    nodeEnv,
    revision,
    appEnv,
  });

  useEffect(() => {
    if (shop) {
      Bugsnag.addMetadata('Shop', {
        id: shop.id,
        name: shop.name,
        domain: shop.url,
      });
    }

    if (userId) {
      Bugsnag.setUser(userId.toString());
    }

    return () => {
      Bugsnag.clearMetadata('Shop');
    };
  }, [shop, userId]);
}
