import * as Types from '../../../types/graphql/core-types';

import type {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
import {gql} from '@apollo/client';
export type StandardMetaobjectDefinitionEnableMutationVariables = Types.Exact<{
  type: Types.Scalars['String']['input'];
}>;

export type StandardMetaobjectDefinitionEnableMutation = {
  __typename: 'Mutation';
  standardMetaobjectDefinitionEnable: {
    __typename: 'StandardMetaobjectDefinitionEnablePayload';
    userErrors: Array<{
      __typename: 'MetaobjectUserError';
      field: Array<string> | null;
      code: Types.MetaobjectUserErrorCode | null;
      message: string;
    }>;
  } | null;
};

export const StandardMetaobjectDefinitionEnableDocument = gql`
  mutation StandardMetaobjectDefinitionEnable($type: String!) {
    standardMetaobjectDefinitionEnable(type: $type) {
      userErrors {
        field
        code
        message
      }
    }
  }
` as unknown as DocumentNode<
  StandardMetaobjectDefinitionEnableMutation,
  StandardMetaobjectDefinitionEnableMutationVariables
>;
