import {useLocation, useNavigate} from '@remix-run/react';
import {type To} from '@remix-run/router';
import {
  NavigationMenu,
  useClientRouting,
  useRoutePropagation,
} from '@shopify/app-bridge-react';
import {useI18n} from '@shopify/react-i18n';
import React, {useMemo} from 'react';

import {createI18nConfig} from '~/utils/createI18nConfig';
import {
  filtersURL,
  productRecommendationsURL,
  searchURL,
  settingsURL,
} from '~/utils/urls';

import translations from './translations';

export function Navigation() {
  const [i18n] = useI18n(createI18nConfig(translations));
  const location = useLocation();
  const navigate = useNavigate();

  // temporary - useClientRouting expects a history-like object that has "replace" on it. react-router no longer exposes history, so we can replicate it with "navigate"
  const fakeHistory = useMemo(() => {
    return {
      replace: (url: To) => {
        navigate(url, {replace: true});
      },
    };
  }, [navigate]);

  useClientRouting(fakeHistory);
  useRoutePropagation(location);

  return (
    <NavigationMenu
      navigationLinks={[
        {
          label: i18n.translate('Navigation.filters'),
          destination: filtersURL(),
        },
        {
          label: i18n.translate('Navigation.search'),
          destination: searchURL(),
        },
        {
          label: i18n.translate('Navigation.recommendations'),
          destination: productRecommendationsURL(),
        },
        {
          label: i18n.translate('Navigation.settings'),
          destination: settingsURL(),
        },
      ]}
      matcher={(link, location) => {
        const pathname =
          typeof location === 'string'
            ? new URL(location).pathname
            : location.pathname;

        return pathname.startsWith(link.destination);
      }}
    />
  );
}
