import {useAppBridge} from '@shopify/app-bridge-react';
import {useCallback, useEffect, useRef, useState} from 'react';

import {useBugsnagContext} from '~/foundation/AppSetupContext';
import {type AppBridgeStateStaffMember} from '~/types';

export function useAppBridgeStateStaffMember() {
  const appBridge = useAppBridge();
  const {notify} = useBugsnagContext();

  const [staffMember, setStaffMember] = useState<
    AppBridgeStateStaffMember | undefined
  >();

  const previousStaffMemberId = useRef<number | undefined>();

  const updateAppBridgeState = useCallback(async () => {
    const time = 10_000;
    const timeout = setTimeout(() => {
      notify(
        new Error(`App bridge state staff member not updated after ${time}ms`),
      );
    }, time);

    const staffMember =
      await appBridge.getState<AppBridgeStateStaffMember>('staffMember');

    if (staffMember) {
      clearTimeout(timeout);
    }

    if (staffMember && staffMember.id !== previousStaffMemberId.current) {
      setStaffMember(staffMember);
      previousStaffMemberId.current = staffMember.id;
    }
  }, [appBridge, notify]);

  useEffect(() => {
    // There was an issue on mobile that required getting app bridge state this way
    updateAppBridgeState();

    const unsubscribeAppBridge = appBridge.subscribe(() => {
      updateAppBridgeState();
    });

    return () => {
      unsubscribeAppBridge();
    };
  }, [appBridge, updateAppBridgeState]);

  return staffMember;
}
