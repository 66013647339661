import {type ApolloClient, useMutation} from '@apollo/client';
import {useEffect, useState} from 'react';

import {
  MetafieldOwnerType,
  MetaobjectUserErrorCode,
  StandardMetafieldDefinitionsEnableUserErrorCode,
} from '~/types/graphql/core-types';
import {MetaObjectDefinitions, MetafieldDefinitions} from '~/utils/constants';

import {StandardMetafieldDefinitionsEnableDocument} from '../graphql/StandardMetafieldDefinitionsEnableMutation.core.graphql.generated';
import {StandardMetaobjectDefinitionEnableDocument} from '../graphql/StandardMetaobjectDefinitionEnableMutation.core.graphql.generated';

export function useEnableMetaDefinitions({
  skip,
  client,
}: {
  skip: boolean;
  client: ApolloClient<any>;
}) {
  const [enablingDefinitions, setEnablingDefinitions] = useState(!skip);
  const [userError, setUserError] = useState<Error>();
  const [bulkEnableMetafieldDefinitions] = useMutation(
    StandardMetafieldDefinitionsEnableDocument,
    {
      client,
    },
  );
  const [standardMetaobjectDefinitionEnable] = useMutation(
    StandardMetaobjectDefinitionEnableDocument,
    {
      client,
    },
  );

  useEffect(() => {
    if (skip) {
      return;
    }

    (async function enableMetafields() {
      try {
        setEnablingDefinitions(true);

        const metafieldDefinitionsToEnable = [
          MetafieldDefinitions.ProductBoosts,
          MetafieldDefinitions.ProductRecommendations,
        ].flatMap(({namespace, metafields}) => {
          return Object.values(metafields).map(({key}) => ({
            ownerType: MetafieldOwnerType.Product,
            namespace,
            key,
          }));
        });

        const [
          {data: metafieldDefinitionData, errors: metafieldDefinitionErrors},
          {data: metaobjectDefinitionData, errors: metaobjectDefinitionErrors},
        ] = await Promise.all([
          bulkEnableMetafieldDefinitions({
            context: {
              skipLocale: true,
            },
            variables: {
              definitions: metafieldDefinitionsToEnable,
            },
          }),
          standardMetaobjectDefinitionEnable({
            context: {
              skipLocale: true,
            },
            variables: {
              type: MetaObjectDefinitions.SynonymGroup.type,
            },
          }),
        ]);

        const unexpectedGQLErrors = [
          ...(metafieldDefinitionErrors ?? []),
          ...(metaobjectDefinitionErrors ?? []),
        ];

        // These are GQL layer errors, not user errors. Therefor we throw only the first one to have complete information inside bugsnag reports.
        if (unexpectedGQLErrors.length > 0) {
          throw unexpectedGQLErrors[0];
        }

        const unexpectedMetafieldErrors = (
          metafieldDefinitionData?.standardMetafieldDefinitionsEnable
            ?.userErrors || []
        ).filter(
          ({code}) =>
            code !== StandardMetafieldDefinitionsEnableUserErrorCode.Taken,
        );

        const unexpectedMetaobjectErrors = (
          metaobjectDefinitionData?.standardMetaobjectDefinitionEnable
            ?.userErrors || []
        ).filter(
          ({code}) =>
            code !== MetaobjectUserErrorCode.ObjectFieldTaken &&
            code !== MetaobjectUserErrorCode.Taken,
        );

        const unexpectedUserErrors = [
          ...unexpectedMetafieldErrors,
          ...unexpectedMetaobjectErrors,
        ];

        if (unexpectedUserErrors.length > 0) {
          throw new Error(
            unexpectedUserErrors
              .map((userError) => userError.message)
              .join('&'),
          );
        }
      } catch (error: any) {
        setUserError(error);
      } finally {
        setEnablingDefinitions(false);
      }
    })();
  }, [
    skip,
    bulkEnableMetafieldDefinitions,
    standardMetaobjectDefinitionEnable,
  ]);

  return {enablingDefinitions, error: userError};
}
