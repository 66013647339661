import {type ApolloClient, useQuery} from '@apollo/client';
import {composeGid} from '@shopify/admin-graphql-api-utilities';
import {useMemo} from 'react';

import {ALL_BETA_FLAG_VALUES} from '~/utils/betaFlags';
import {
  DISMISSABLE_CARDS_MAP,
  type DismissableCardHandle,
} from '~/utils/constants';
import {filterFalseArray} from '~/utils/filterFalseArray';

import {DismissibleCardsDocument} from '../graphql/DismissibleCardsQuery.core.graphql.generated';
import {SharedDataContextDocument} from '../graphql/SharedDataContextQuery.core.graphql.generated';

interface useLoadShopDataProps {
  userId: number | undefined;
  client: ApolloClient<any>;
}

export function useLoadShopData({userId, client}: useLoadShopDataProps) {
  const userGid = userId ? composeGid('StaffMember', userId) : undefined;

  const {
    data: sharedDataResults,
    loading: sharedDataLoading,
    error: sharedDataError,
  } = useQuery(SharedDataContextDocument, {
    variables: {
      id: userGid || '',
      enabledBetasNames: [...ALL_BETA_FLAG_VALUES],
    },
    ssr: false,
    skip: !userGid,
    client,
  });

  const {
    data: dismissibleCardsResult,
    loading: dismissibleCardsLoading,
    refetch: refetchDismissibleCards,
    error: dismissibleCardsError,
  } = useQuery(DismissibleCardsDocument, {
    variables: DISMISSABLE_CARDS_MAP,
    ssr: false,
    client,
  });

  const sharedDataValue = useMemo(() => {
    if (
      userId === undefined ||
      !sharedDataResults?.staffMember ||
      !sharedDataResults.app
    ) {
      return undefined;
    }

    return {
      userId,
      shop: {
        ...sharedDataResults.shop,
      },
      app: {
        ...sharedDataResults.app,
        appStoreAppUrl:
          sharedDataResults.app.appStoreAppUrl ||
          'https://apps.shopify.com/search-and-discovery',
        handle: sharedDataResults.app.handle || 'search-and-discovery',
      },
      staffMember: {
        email: sharedDataResults.staffMember.email,
        pin: sharedDataResults.staffMember.pin || '',
        adminPermissions:
          sharedDataResults.staffMember.permissions.userPermissions,
      },
    };
  }, [sharedDataResults, userId]);

  const dismissedCards = useMemo(() => {
    if (!dismissibleCardsResult) {
      return undefined;
    }

    const {overview_FeedbackCard, overview_FilterSortCard} =
      dismissibleCardsResult;

    const dismissedCards = filterFalseArray<DismissableCardHandle>([
      overview_FeedbackCard && DISMISSABLE_CARDS_MAP.overviewFeedbackCard,
      overview_FilterSortCard && DISMISSABLE_CARDS_MAP.overviewFilterSortCard,
    ]);

    return dismissedCards;
  }, [dismissibleCardsResult]);

  return {
    sharedDataLoading,
    sharedDataValue,
    sharedDataError: sharedDataError || dismissibleCardsError,
    dismissedCards,
    dismissibleCardsLoading,
    refetchDismissibleCards,
  };
}
