import {isRouteErrorResponse, useRouteError} from '@remix-run/react';
import React, {useEffect} from 'react';

import {NotFound} from '~/components';
import {ShowError} from '~/foundation/AppSetupContext';
import {notify} from '~/foundation/AppSetupContext/context';
import {convertUnknownToError} from '~/utils/convertUnknownToError';

export function AppErrorBoundary() {
  const routeError = useRouteError();
  const isRouteError = isRouteErrorResponse(routeError);
  useEffect(() => {
    const usableError = convertUnknownToError(routeError);
    if (usableError) {
      notify(usableError);
    }
  }, [routeError]);

  if (isRouteError && routeError.status === 404) {
    return <NotFound />;
  }

  return <ShowError />;
}
