import translations from '@shopify/polaris-internal/locales/en.json';
import {I18n, I18nManager} from '@shopify/react-i18n';
import {useEffect, useMemo, useState} from 'react';

import {CurrencyCode} from '~/types/graphql/core-types';
import {isProduction} from '~/utils/env';

interface useI18nManagerProps {
  locale: string | undefined;
  currencyCode: CurrencyCode | undefined;
}

export function useI18nManager({
  locale = 'en',
  currencyCode = CurrencyCode.Usd,
}: useI18nManagerProps) {
  const [i18n, setI18n] = useState<I18n | null>(null);

  const i18nManager = useMemo(() => {
    return new I18nManager({
      locale,
      currency: currencyCode,
      // a (hopefully) temporary fix for https://github.com/Shopify/quilt/issues/1685
      onError(error) {
        if (isProduction) {
          // eslint-disable-next-line no-console
          console.error(error);
        } else {
          throw error;
        }
      },
    });
  }, [currencyCode, locale]);

  // loads the Polaris translations for our i18n manager
  // this is used instead of usei18n because then we'd need another layer of context wrapping this component
  // taken from: https://github.com/Shopify/quilt/blob/main/packages/react-i18n/src/hooks.tsx#L39
  useEffect(() => {
    const id = 'Polaris';

    i18nManager.register({
      id,
      fallback: translations,
      translations(locale) {
        // Note: this needs to be a relative import with the way vite works: https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
        return import(
          `../../../../../node_modules/@shopify/polaris-internal/locales/${locale}.json`
        );
      },
    });

    const unsubscribe = i18nManager.subscribe(
      [id],
      ({translations, loading}, details) => {
        setI18n(new I18n(translations, {...details, loading}));
      },
    );

    return unsubscribe;
  }, [i18nManager]);

  return {i18nManager, i18n};
}
