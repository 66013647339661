import * as Types from '../../../types/graphql/core-types';

import type {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
import {gql} from '@apollo/client';
export type DismissibleCardsQueryVariables = Types.Exact<{
  overviewFeedbackCard: Types.Scalars['String']['input'];
  overviewFilterSortCard: Types.Scalars['String']['input'];
}>;

export type DismissibleCardsQuery = {
  __typename: 'QueryRoot';
  overview_FeedbackCard: boolean;
  overview_FilterSortCard: boolean;
};

export const DismissibleCardsDocument = gql`
  query DismissibleCards(
    $overviewFeedbackCard: String!
    $overviewFilterSortCard: String!
  ) {
    overview_FeedbackCard: elementDismissed(handle: $overviewFeedbackCard)
    overview_FilterSortCard: elementDismissed(handle: $overviewFilterSortCard)
  }
` as unknown as DocumentNode<
  DismissibleCardsQuery,
  DismissibleCardsQueryVariables
>;
