import {isRouteErrorResponse} from '@remix-run/react';

export function convertUnknownToError(error: unknown) {
  if (!error) {
    return;
  }

  if (
    typeof error === 'object' &&
    'error' in error &&
    error.error instanceof Error
  ) {
    return error.error;
  }

  if (isRouteErrorResponse(error)) {
    const {data, statusText, status} = error;
    return new Error(data.message || `${status} error: ${statusText}`);
  }

  if (error instanceof Error) {
    return error;
  }

  if (typeof error === 'string') {
    return new Error(error);
  }
}
