import * as Types from '../../../types/graphql/core-types';

import type {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
import {gql} from '@apollo/client';
export type StandardMetafieldDefinitionsEnableMutationVariables = Types.Exact<{
  definitions:
    | Array<Types.StandardMetafieldDefinitionsEnableInput>
    | Types.StandardMetafieldDefinitionsEnableInput;
}>;

export type StandardMetafieldDefinitionsEnableMutation = {
  __typename: 'Mutation';
  standardMetafieldDefinitionsEnable: {
    __typename: 'StandardMetafieldDefinitionsEnablePayload';
    userErrors: Array<{
      __typename: 'StandardMetafieldDefinitionsEnableUserError';
      code: Types.StandardMetafieldDefinitionsEnableUserErrorCode | null;
      field: Array<string> | null;
      message: string;
    }>;
  } | null;
};

export const StandardMetafieldDefinitionsEnableDocument = gql`
  mutation StandardMetafieldDefinitionsEnable(
    $definitions: [StandardMetafieldDefinitionsEnableInput!]!
  ) {
    standardMetafieldDefinitionsEnable(definitions: $definitions) {
      userErrors {
        code
        field
        message
      }
    }
  }
` as unknown as DocumentNode<
  StandardMetafieldDefinitionsEnableMutation,
  StandardMetafieldDefinitionsEnableMutationVariables
>;
