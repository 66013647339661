import React from 'react';

import {AppErrorBoundary} from '~/foundation/AppErrorBoundary/AppErrorBoundary';

interface RenderChildrenOrThrowProps {
  error?: Error;
  children: React.ReactNode;
}

export function RenderChildrenOrThrow({
  error,
  children,
}: RenderChildrenOrThrowProps) {
  if (error) {
    return <AppErrorBoundary />;
  }
  return children;
}
